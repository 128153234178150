import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Usuario } from '../models/usuario';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    public usuario: Usuario | null = null;
    private go: string;

    constructor(
        private storage: StorageService,
        private router: Router,
        private http: HttpClient,
        private network: Network
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.go = state.url;
        this.storage.get('user').then(r => {
            if (!r) {
                this.router.navigate(['/hall']);
            }
        });
        return this.storage.has('user');
    }

    login(data: { login: string; senha: string }) {
        this.http.post(environment.url + 'login', data).subscribe(r => {
            // Encriptar o token
            this.storage.set('token', r).then(() => {
                this.getUsuario().subscribe(() => {
                    this.router.navigate(['/']);
                });
            });
        });
    }

    logout() {
        this.http.post(environment.url + 'logout', this.usuario).subscribe(
            r => {
                this.usuario = null;
                this.storage.remove('user').then(() => {
                    this.router.navigate(['/hall']);
                });
            }
        );
    }

    getUsuario(): Observable<boolean> {
        return new Observable(subscriber => {
            const execute = () => {
                this.storage.get('token').then(token => {
                    if (token) {
                        if (
                            this.network.type !== this.network.Connection.NONE
                        ) {
                            this.http.get<Usuario>(environment.url + 'user', {
                                headers: {
                                    authorization: 'Bearer ' + token.access_token
                                }
                            }).subscribe(
                                u => {
                                    this.storage.set('user', u);
                                    this.usuario = u;
                                    subscriber.next(true);

                                    this.router.navigateByUrl(this.go);
                                },
                                e => {
                                    this.usuario = null;
                                    subscriber.next(false);

                                    //if(e.status === 403){
                                    //this.router.navigateByUrl('/assinatura');
                                    //}
                                }
                            );
                        } else {
                            this.storage.get('user').then(u => {
                                this.usuario = u;
                                subscriber.next(true);

                                this.router.navigateByUrl(this.go);
                            });
                        }
                    } else {
                        this.usuario = null;
                        subscriber.next(false);
                    }
                });
            };

            const INT = setInterval(() => {
                if (this.storage.hasInit()) {
                    clearInterval(INT);
                    execute();
                }
            }, 100);
        });
    }

    register(data: any) {
        return new Observable(subscriber => {
            const execute = () => {
                this.http.post(environment.url + 'register', data).subscribe(
                    u => {
                        this.storage.set('user', u);
                        this.usuario = u;
                        subscriber.next(true);

                        this.router.navigateByUrl(this.go);
                    },
                    e => {
                        this.usuario = null;
                        subscriber.next(false);

                        //if(e.status === 403){
                        //this.router.navigateByUrl('/assinatura');
                        //}
                    }
                );
            };

            const INT = setInterval(() => {
                if (this.storage.hasInit()) {
                    clearInterval(INT);
                    execute();
                }
            }, 100);
        });
    }

    resetar(data: any) {
        this.http.post(environment.url + 'resetar', data).subscribe(r => {});
    }
}
