import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private storage: Storage, private alertController: AlertController, private loadingController: LoadingController, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        if(request.method === 'GET'){
            const data = {};

            request.params.keys().forEach((k) => {
                if(request.params.getAll(k).length > 1){
                    data[k] = request.params.getAll(k);
                }else{
                    data[k] = request.params.get(k);
                }
            });
            request = request.clone({params : this.getParams(data)});
        }

        return from(this.storage.get('token'))
            .pipe(
                switchMap((token) => {

                    if (token) {
                        request = request.clone({ headers: request.headers.set('Authorization', token.token_type + ' ' + token.access_token) });
                    }

                    return from(this.showLoader()).pipe(
                        switchMap((loader) => {
                            if (!request.headers.has('showLoader') || (request.headers.has('showLoader') && request.headers.get('showLoader') === 'true')) {
                                loader.present();
                            }

                            return next.handle(request).pipe(
                                map((event: HttpEvent<any>) => {
                                    if (event instanceof HttpResponse) {
                                        loader.dismiss();
                                    }
                                    return event;
                                }),
                                catchError((reject: HttpErrorResponse) => {
                                    loader.dismiss();

                                    switch (reject.status) {
                                        case 400:
                                            if (reject.error.error === 'invalid_grant') {
                                                this.presentAlert('Login inválido, verifique seu usuário e senha.', null);
                                            } else {
                                                this.presentAlert(reject.error.message, null);
                                            }
                                            break;
                                        case 401:
                                            let okHandler = () => {};
                                            // if (environment.production) {
                                                this.storage.clear();
                                                okHandler = () => {
                                                    this.router.navigate(['/login']);
                                                };
                                            // }
                                            this.presentAlert(reject.error.message, 'Autenticação inválida', okHandler);
                                            break;
                                        case 402:
                                            if(reject.error.error === 'user_inactive'){
                                                this.router.navigate(['/analise']);
                                            }else{
                                                this.router.navigate(['/assinatura']);
                                            }
                                            break;
                                        case 403:
                                            this.presentAlert('Você não tem autorização para executar essa operação.', null);
                                            break;
                                        case 404:
                                            //Pode ser um modelo não encontrado então simplesmente não mostra nada
                                            break;
                                        case 422:
                                            const sub = 'Dados inválidos!';
                                            let mensagem = '';
                                            Object.keys(reject.error.errors).forEach((key) => {
                                                mensagem = reject.error.errors[key][0];
                                            });
                                            this.presentAlert(mensagem, sub);
                                            break;
                                        case 500:
                                        default:
                                            this.presentAlert('Estamos verificando o problema tente novamente mais tarde.');
                                    }

                                    console.log(reject);

                                    return throwError(reject);
                                })
                            );
                        })
                    );
                })
            );
    }

    async presentAlert(mensagem, sub = 'não foi possível completar a requisição', okHanlder = () => { }) {
        const alert = await this.alertController.create({
            header: 'Ops!',
            cssClass: 'alert-dux',
            subHeader: sub,
            message: mensagem,
            buttons: [
                //{cssClass : 'alert-button-cancel', text: 'Cancelar'},
                {
                    cssClass: 'alert-button-ok',
                    text: 'OK',
                    handler: okHanlder
                }
            ]
        });

        await alert.present();
    }

    showLoader(): Promise<HTMLIonLoadingElement> {
        return this.loadingController.create({
            cssClass: 'app-loader',
            message: 'Aguarde ...',
        });
    }

    private getParams(query) {
        let params: HttpParams = new HttpParams();
        for (const key of Object.keys(query)) {
            if (query[key]) {
                if (query[key] instanceof Array) {
                    query[key].forEach((item) => {
                        params = params.append(`${key.toString()}[]`, item);
                    });
                } else {
                    params = params.append(key.toString(), query[key]);
                }
            }
        }

        return params;
    }
}
