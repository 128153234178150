import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';
import { Interceptor } from './providers/interceptor';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import * as Sentry from "sentry-cordova";
import { SentryIonicErrorHandler } from './handlers/SentryIonicErrorHandler';

Sentry.init({
    dsn: "https://56293fba49df4bc7aa29b98f6cd1c705@o1195823.ingest.sentry.io/6318930"
});

registerLocaleData(localePt, 'pt-BR', localeExtraPT);

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

// register FullCalendar plugins
FullCalendarModule.registerPlugins([
    dayGridPlugin,
    interactionPlugin
]);

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot({
            name: '__netsafety',
            //driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        FullCalendarModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        httpInterceptorProviders,
        Network,
        {provide: ErrorHandler, useClass: SentryIonicErrorHandler}
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
