import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthGuard } from './guards/auth.guard';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

    constructor(public auth: AuthGuard, public router: Router, private alertController: AlertController) { }

    ngOnInit(){
        this.auth.getUsuario().subscribe((r) => {
            //SplashScreen.hide();
        });
    }

    logout(){
        this.alertController.create({
            header: 'Confirmação',
            message: 'Deseja deslogar da aplicação? Você precisará entrar com seu usuário e senha novamente.',
            buttons: [
                {
                    text: 'Cancelar'
                },
                {
                    text: 'Sim',
                    handler: () => {
                        this.auth.logout();
                    }
                }
            ]
        }).then(a => a.present());
    }
}
