import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage: Storage | null = null;


    constructor(private storage: Storage) {
        this.init();
    }

    hasInit(){
        return this._storage ? true : false;
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    public set(key: string, value: any) {
        return this._storage?.set(key, value);
    }

    public get(key: string) {
        return this._storage?.get(key);
    }

    public remove(key: string) {
        return this._storage?.remove(key);
    }

    public has(k: string){
        return this._storage.keys().then((keys) => keys.indexOf(k) !== -1);
    }
}
