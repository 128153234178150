import { ErrorHandler } from '@angular/core';

import * as Sentry from 'sentry-cordova';

export class SentryIonicErrorHandler extends ErrorHandler {
    handleError(error) {
        super.handleError(error);
        try {
            Sentry.captureException(error.originalError || error);
        } catch (e) {
            console.error('Sentry error logging failed', e);
        }
    }
}
